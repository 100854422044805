import CardContent from "@mui/material/CardContent";
import CardDX from "../../../../components/layout/carddx";
import GridDX from "../../../../components/layout/griddx";
import Loading from "../../../../components/loading";
import Typography from "@mui/material/Typography";
import { CardActions, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TextFieldDX from "../../../../components/controls/textfielddx";
import { useNotificationContext } from "../../../../context/notificationcontext";
import DocumentTemplateTab from "./documenttemplatetab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useDocumentTemplateService from "../../../../shared/services/documenttemplateservice";
import EditTemplateModal from "../../../../components/editmodals/tools/documenttemplatemodal";
import ButtonDX from "../../../../components/controls/buttondx";

import { ContentState, EditorState, convertFromHTML } from "draft-js";
import TextEditorDX from "../../../../components/business/texteditordx";

const DocumentTemplate = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const [open, setOpen] = useState(false);
  const { getDocumentTemplateByID } = useDocumentTemplateService();
  const [templateData, setTemplateData] = useState<any>({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);
  const [refetchStatusData, setRefetchStatusData] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    setIsLoading(true);
    getDocumentTemplateByID(Number(id))
      .then((res) => {
        setTemplateData(res);
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(res.content).contentBlocks // Assuming you have a property named 'content' for each document
        );
        const editorStateFromHtml = EditorState.createWithContent(contentState);
        setEditorState(editorStateFromHtml);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toClose = (refreshPage: boolean = false) => {
    setOpen(false);

    if (refreshPage) {
      getDetails();
      setRefetchStatusData(!refetchStatusData);
    }
  };

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}

      {open && (
        <EditTemplateModal
          open={open}
          handleClose={toClose}
          documentTemplateData={templateData}
        />
      )}
      <CardDX>
        <CardContent>
          <GridDX container sx={{ mb: 2 }}>
            <GridDX item xs={10}>
              <Typography fontSize={22}>
                <InfoOutlinedIcon fontSize="medium" /> {t("Document Template")}
              </Typography>
            </GridDX>
            <GridDX item xs={2}>
              <ButtonDX onClick={() => setOpen(true)}>{t("Edit")}</ButtonDX>
            </GridDX>
          </GridDX>

          <Divider sx={{ mb: 1 }} />
          <GridDX fullWidth>
            <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
              <GridDX item xs={6}>
                <TextFieldDX
                  readOnly
                  label={t("English Name")}
                  name="from"
                  value={templateData?.enName}
                  placeholder={t("No Information")}
                />
              </GridDX>
              <GridDX item xs={6}>
                <TextFieldDX
                  readOnly
                  label={t("Arabic Name")}
                  name="from"
                  value={templateData?.arName}
                  placeholder={t("No Information")}
                />
              </GridDX>
              <GridDX item xs={12}>
                <Typography fontSize={22}>
                  {t("Document Template Content")}
                </Typography>
              </GridDX>

              <GridDX item xs={12}>
                <TextEditorDX
                  editorState={editorState}
                  setEditorState={setEditorState}
                  disabled
                />
              </GridDX>
            </GridDX>
          </GridDX>

          <GridDX fullWidth>{/* Text editor */}</GridDX>
        </CardContent>
      </CardDX>

      <GridDX item xs={12} justifyContent="start">
        <CardDX sx={{ mt: 2, width: "100%" }}>
          <CardContent>
            <DocumentTemplateTab
              getDetails={getDetails}
              correspondenceId={parseInt(id as string)}
              refetchStatusData={refetchStatusData}
            />
          </CardContent>
        </CardDX>
      </GridDX>
    </GridDX>
  );
};

export default DocumentTemplate;
