import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../../context/notificationcontext";
import useUserService from "../../../shared/services/userservices";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import GridDX from "../../layout/griddx";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useAuthContext } from "../../../context/authcontext";
import SelectListDX from "../../controls/selectlistdx";
import { Chip, Typography } from "@mui/material";
import moment from "moment";
import DatePickerDX from "../../controls/datepickerdx";
import TextFieldDX from "../../controls/textfielddx";
import LoadingButtonDX from "../../controls/loadingbuttondx";

const TransactionModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();
  const { userData } = useAuthContext();
  const languageIsEn = i18n.language === "en";

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    getUsersList();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (items.length === 0)
      newErrors["users"] = t("This field cannot be empty");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getUsersList = async () => {
    // setIsLoading(true);
    // getActiveUsers()
    //   .then((data: any) => {
    //     const users = data.filter(
    //       (user: any) =>
    //         !props.followers.includes(user.id) && user.id !== userData.userId
    //     );

    //     const list = users.map((res: any) => ({
    //       text: languageIsEn ? res.enFullName : res.arFullName,
    //       value: res.id,
    //     }));
    //     setUsersList(list);
    //   })
    //   .catch((err) => setError(err))
    //   .finally(() => setIsLoading(false));
  };

  const onSave = async () => {
    if (validateForm()) {}
  };

  const handleInputChange = (newValue: any) => {
    setIsChanged(true);
    setItems(newValue);
  };

  return (
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("Add Transaction")}
      maxWidth="md"
      onSaveClick={onSave}
    >
      <GridDX
        container
        sx={{ width: "100%", height: "100%", my: 1 }}
        alignItems="center"
        columnSpacing={1}
        rowSpacing={2}
      >
          <GridDX item xs={4}>
            <SelectListDX
              label={t("Type")}
              items={[
                {text: "Internal", value: 1},
                {text: "External", value: 2},
              ]}
              name="type"
            />
          </GridDX>
          <GridDX item xs={4}>
            <SelectListDX
              label={t("Receiver")}
              items={[
                {text: "A", value: 1},
                {text: "B", value: 2},
              ]}
              name="receiver"
            />
          </GridDX>
          <GridDX item xs={4}>
            <SelectListDX
              label={t("Entity")}
              items={[
                {text: "X", value: 1},
                {text: "Y", value: 2},
              ]}
              name="entity"
            />
          </GridDX>
          <GridDX xs={3} pt={1}>
            <GridDX
              component="fieldset"
              sx={{
                ml: 1,
                mr: 0,
                height: 65,
                width: "100%",
                border: "1px solid #c6c6c6",
                borderRadius: 1,
              }}
            >
              <legend style={{ color: "#808080", fontSize: 12 }}>
                {t("Document")}
              </legend>
              <Chip
                label={"fileName.pdf"}
                color="primary"
              />
            </GridDX>
          </GridDX>
          <GridDX item xs={3}>
            <SelectListDX
              label={t("Must Be Returned")}
              items={[
                {text: "Yes", value: 1},
                {text: "No", value: 2},
              ]}
              name="return"
            />
          </GridDX>
          <GridDX item xs={3}>
            <DatePickerDX
              label={t("Date")}
              sx={{ flex: 1 }}
              name="date"
              value={moment()}
              calendarId={1}
            />
          </GridDX>
          <GridDX item xs={3}>
            <DatePickerDX
              label={t("Return Date")}
              sx={{ flex: 1 }}
              name="returnDate"
              value={moment()}
              calendarId={1}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Type")}
              items={[
                {text: "Internal", value: 1},
                {text: "External", value: 2},
              ]}
              name="type"
            />
          </GridDX>
          <GridDX xs={6} pt={1}>
            <GridDX
              component="fieldset"
              sx={{
                ml: 1,
                mr: 0,
                height: 65,
                width: "100%",
                border: "1px solid #c6c6c6",
                borderRadius: 1,
              }}
            >
              <legend style={{ color: "#808080", fontSize: 12 }}>
                {t("Attachment")}
              </legend>
              <>
                <LoadingButtonDX
                  variant="contained"
                  component="label"
                  sx={{ height: 32 }}
                  loading={false}
                >
                  {t("Upload")}
                  <input type="file" hidden onChange={()=>{}} />
                </LoadingButtonDX>
                <LoadingButtonDX
                  sx={{ mx: 1, height: 32 }}
                  onClick={()=>{}}
                  loading={false}
                >
                  {t("Scan")}
                </LoadingButtonDX>
              </>
            </GridDX>
          </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Notes")}
            fullWidth
            multiline
            name="Notes"
            rows={3}
            // error={}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default TransactionModal;
