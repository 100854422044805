import { PDFDocument, PDFPage, StandardFonts, degrees, rgb } from "pdf-lib";
import * as pdfjs from "pdfjs-dist";
import { multipleImageOCR } from "./globals";

export const createNewPDF = async () => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage();

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}; 

const drawWatermark = (page: PDFPage, text: string) => {
  const { width, height } = page.getSize();
  for (let i = 0; i < height; i += height / 10) {
    for (let j = 10; j < width; j += width / 10) {
      page.drawText(text, {
        x: j,
        y: i,
        size: 10,
        rotate: degrees(-45),
        opacity: 0.25,
      });
    }
  }
};

export const addWatermarkToPDF = async (file: any, text: string) => {
  const pdfDoc = await PDFDocument.load(file);
  const pages = pdfDoc.getPages();
  pages.forEach((value) => drawWatermark(value, text));
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addImageToPDF = async (
  file: any,
  img: any,
  imgType: any,
  dim: any
) => {
  const pdfDoc = await PDFDocument.load(file);
  const pages = pdfDoc.getPages();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(dim.scale);
  const page = pages[0];
  page.drawImage(image, {
    x: dim.x,
    y: dim.y,
    width: dims.width,
    height: dims.height,
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addSignatureToPDF = async (
  file: any,
  img: any,
  imgType: any,
  timeStamp: string,
  dim: any
) => {
  const pdfDoc = await PDFDocument.load(file);
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const pages = pdfDoc.getPages();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(dim.scale);
  const page = pages[dim.page - 1];
  console.log(pages, page);
  const { width, height } = page.getSize();
  page.drawImage(image, {
    x: dim.x,
    y: height - dim.y,
    width: dims.width,
    height: dims.height,
  });
  page.drawText(timeStamp, {
    x: dim.x,
    y: height - dim.y - 8,
    size: 8,
    font: timesRomanFont,
    color: rgb(0, 0, 1),
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const addTextToPDF = async (file: any, text: string, dim: any) => {
  const pdfDoc = await PDFDocument.load(file);
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const pages = pdfDoc.getPages();
  const page = pages[0];
  page.drawText(text, {
    x: dim.x,
    y: dim.y,
    size: 12,
    font: timesRomanFont,
    color: rgb(0, 0, 0),
  });
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const mergePDFs = async (pdf1: any, pdf2: any) => {
  const pdfDoc1 = await PDFDocument.load(pdf1);
  const pdfDoc2 = await PDFDocument.load(pdf2);

  const copiedPages = await pdfDoc1.copyPages(
    pdfDoc2,
    pdfDoc2.getPageIndices()
  );
  copiedPages.forEach((page) => {
    pdfDoc1.addPage(page);
  });
  const pdfBytes = await pdfDoc1.save();
  return pdfBytes;
};

export const createReportPDFs = async (pdf1: any, img: any, imgType: any) => {
  const pdfDoc = await PDFDocument.create();

  const page = pdfDoc.addPage();
  const { width, height } = page.getSize();
  const image =
    imgType === "png" ? await pdfDoc.embedPng(img) : await pdfDoc.embedJpg(img);
  const dims = image.scale(0.25);
  const [embeddedPage] = await pdfDoc.embedPdf(pdf1);
  const embedDims = embeddedPage.scale(1);

  page.drawImage(image, {
    x: 5,
    y: height-dims.height-5,
    width: dims.width,
    height: dims.height,
  });

  page.drawPage(embeddedPage, {
    ...embedDims,
    x: 5,
    y: height-dims.height-embedDims.height-30,
  });

  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
};

export const convertPdfToImages = async (file: any) => {
  const buffer = await file.arrayBuffer().then((res: any) => res);
  pdfjs.GlobalWorkerOptions.workerSrc = "/pdfjs-worker/pdf.worker.mjs";
  let images: any = [];
  const pdf = await pdfjs.getDocument(buffer).promise;
  const canvas = document.createElement("canvas");
  for (let i = 0; i < pdf.numPages; i++) {
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 1 });
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvas.style.width = "100%";
    await page.render({ canvasContext: context, viewport: viewport }).promise;
    images.push(canvas.toDataURL());
  }
  canvas.remove();
  return images;
};

export const getContentFromPdf = async (file: any) => {
  const imagesFromPDF = await convertPdfToImages(file);

  console.log({ imagesFromPDF });

  const content = await multipleImageOCR(imagesFromPDF);
  return content;
};
