import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CardActions,
  CardContent,
  Chip,
  Divider,
  Typography,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import LegendToggleOutlinedIcon from "@mui/icons-material/LegendToggleOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import CorrespondenceTab from "./correspondencetab";

import GridDX from "../../../components/layout/griddx";
import TextFieldDX from "../../../components/controls/textfielddx";
import ButtonDX from "../../../components/controls/buttondx";
import CardDX from "../../../components/layout/carddx";
import BoxDX from "../../../components/layout/boxdx";
import MenuButtonDX from "../../../components/controls/menubutton";
import MenuItemDX from "../../../components/controls/menuitemdx";
import EditCorrespondenceModal from "../../../components/editmodals/correspondence/editcorrespondencemodal";
import Loading from "../../../components/loading";
import LoadingButtonDX from "../../../components/controls/loadingbuttondx";
import DeleteModal from "../../../components/controls/deleteModal";
import CorrepondenceConfirmModal from "../../../components/controls/correspondenceconfirmmodal";
import SignatureModal from "../../../components/editmodals/signaturemodal";
import ScanModal from "../../../components/editmodals/scanmodal";

import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";

import useMainAttachmentService from "../../../shared/services/mainattachmentservice";
import useUserService from "../../../shared/services/userservices";
import { addWatermarkToPDF } from "../../../shared/pdfoperations";
import { CorrespondenceStatus, DateFormatter } from "../../../shared/globals";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import useInboxService from "../../../shared/services/inboxservice";
import TypeTranslator from "../../../shared/typetranslator";
import useCorrespondenceActionService from "../../../shared/services/correspondenceactionservice";

const CorrespondenceDetails = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { getCorrespondenceDetails, getCorrespondenceStats } =
    useCorrespondenceService();
  const {
    approveCorrespondence,
    rejectCorrespondence,
    reviewCorrespondence,
    recallCorrespondence,
  } = useCorrespondenceActionService();
  const { updateMainAttachment, deleteMainAttachment } =
    useMainAttachmentService();
  const { getInboxByUser } = useInboxService();
  const { getUserById } = useUserService();
  const { getCorrespondenceStatusValue } = TypeTranslator();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [correspondenceData, setCorrespondenceData] = useState<any>({});
  const [action, setAction] = useState<any>(null);
  const [correspondenceStats, setCorrespondenceStats] = useState<any>({});
  const [refetchStatusData, setRefetchStatusData] = useState(false);
  const [signature, setSignature] = useState<any>(null);

  const [openCorrespondenceConfirmModal, setOpenCorrespondenceConfirmModal] =
    useState(false);
  const [correspondenceConfirmModalLabel, setCorrespondenceConfirmModalLabel] =
    useState("");
  const [confirmClickFn, setConfirmClickFn] = useState<any>(null);
  const [openSignatureModal, setOpenSignatureModal] = useState(false);

  useEffect(() => {
    getDetails(parseInt(id as string));
  }, []);

  const getDetails = async (Id: number) => {
    setIsLoading(true);
    const p1 = getCorrespondenceDetails(Id);
    const p2 = getCorrespondenceStats(Id);
    const p3 = getAction();
    Promise.all([p1, p2, p3])
      .then(([correspondenceData, correspondenceStats, actionObject]) => {
        setCorrespondenceData(correspondenceData);
        setCorrespondenceStats(correspondenceStats);
        setAction(actionObject);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getAction = async () => {
    return getInboxByUser(userData.userId)
      .then((inbox: any) => {
        const action = inbox.filter(
          (msg: any) =>
            msg.correspondenceId === parseInt(id as string) &&
            msg.actionDatetime === null &&
            msg.archivedDate === null
        );
        if (action.length > 0) return action[0];
        else return null;
      })
      .catch((err) => setError(err));
  };

  const onApproveCorrespondence = async () => {
    handleCloseCorrespondenceConfirmModal();
    setIsSaving(true);
    if (action.requireSignature) {
      if (correspondenceData.mainAttachment === "") {
        setError(t("No attachment uploaded to add signature"));
      } else {
        setOpenSignatureModal(true);
      }
      setIsSaving(false);
    } else toApproveCorrespondence();
  };

  const toApproveCorrespondence = async () => {
    approveCorrespondence(parseInt(id as string), userData.userId)
      .then(() => {
        getDetails(parseInt(id as string));
        setInfo(t("Correspondence approved successfully"));
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const toRejectCorrespondence = async () => {
    handleCloseCorrespondenceConfirmModal();
    setIsSaving(true);
    rejectCorrespondence(parseInt(id as string), userData.userId)
      .then(() => {
        getDetails(parseInt(id as string));
        setInfo(t("Correspondence rejected successfully"));
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const toRecallCorrespondence = async () => {
    handleCloseCorrespondenceConfirmModal();
    setIsSaving(true);
    recallCorrespondence(parseInt(id as string), userData.userId)
      .then(() => {
        getDetails(parseInt(id as string));
        setInfo(t("Correspondence recalled successfully"));
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const toReviewCorrespondence = async () => {
    handleCloseCorrespondenceConfirmModal();
    setIsSaving(true);
    reviewCorrespondence(parseInt(id as string), userData.userId)
      .then(() => {
        getDetails(parseInt(id as string));
        setInfo(t("Correspondence reviewed successfully"));
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const handleOpenCorrespondenceConfirmModal = (
    confirmClickFn: any,
    label: any
  ) => {
    setOpenCorrespondenceConfirmModal(true);
    setConfirmClickFn(() => confirmClickFn);
    setCorrespondenceConfirmModalLabel(label);
  };

  const handleCloseCorrespondenceConfirmModal = () => {
    setCorrespondenceConfirmModalLabel("");
    setOpenCorrespondenceConfirmModal(false);
  };

  const toUploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("ExistingMainAttachmentId", "-1");
    formData.append("CorrespondenceId", correspondenceData.correspondenceId);
    formData.append("MainAttachmentFile", file);

    setIsLoading(true);
    updateMainAttachment(formData)
      .then((result) => {
        setInfo(t("Attachment added successfully"));
        getDetails(parseInt(id as string));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toRemoveFile = async (fileData: string) => {
    setIsLoading(true);
    deleteMainAttachment(correspondenceData.mainAttachmentId)
      .then((res) => {
        setInfo(t("Attachment removed successfully"));
        getDetails(parseInt(id as string));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toClose = (refreshPage: boolean = false) => {
    setOpen(false);

    if (refreshPage) {
      getDetails(parseInt(id as string));
      setRefetchStatusData(!refetchStatusData);
    }
  };

  const getStatusChipColor = (status: number) => {
    switch (status) {
      case 1:
        return "primary";
      case 2:
        return "primary";
      case 3:
        return "success";
      case 4:
        return "success";
      case 5:
        return "error";
      case 6:
        return "primary";
    }
  };

  const getImportanceChipColor = (level: string) => {
    if (level === "Urgent") return "error";
    else return "info";
  };

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}

      {openCorrespondenceConfirmModal && (
        <CorrepondenceConfirmModal
          open={openCorrespondenceConfirmModal}
          onConfirmClick={confirmClickFn}
          label={correspondenceConfirmModalLabel}
          onCancelClick={handleCloseCorrespondenceConfirmModal}
        />
      )}

      {open && (
        <EditCorrespondenceModal
          open={open}
          handleClose={toClose}
          correspondenceData={correspondenceData}
        />
      )}

      {openSignatureModal && (
        <SignatureModal
          open={openSignatureModal}
          onClose={() => setOpenSignatureModal(false)}
          data={correspondenceData}
          toApprove={toApproveCorrespondence}
        />
      )}

      <GridDX item xs={12}>
        <Typography fontSize={25} gutterBottom>
          {`${id} - ${correspondenceData?.subject ?? ""}`}
        </Typography>
        <Chip
          sx={{ m: 0.5, height: 35, fontSize: 16 }}
          label={getCorrespondenceStatusValue(correspondenceData?.status)}
          color={getStatusChipColor(correspondenceData?.status)}
        />
      </GridDX>

      <CardDX>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <InfoOutlinedIcon fontSize="medium" /> {t("Basic Information")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <GridDX fullWidth>
            <GridDX item xs={10} flexDirection="column">
              <GridDX>
                <Chip
                  sx={{ m: 0.5 }}
                  label={
                    t("Routing Group") +
                    `: ${
                      (i18n.language === "en"
                        ? correspondenceData?.routingGroup?.enName
                        : correspondenceData?.routingGroup?.arName)
                    ?? ""}`
                  }
                  color="primary"
                />
                {correspondenceData?.dueDate && (
                  <Chip
                    sx={{ m: 0.5 }}
                    label={
                      t("Due Date") +
                      " : " +
                      DateFormatter(
                        correspondenceData?.dueDate,
                        userData?.calendarId,
                        userData?.gregorianDateFormatId,
                        userData?.hijriDateFormatId
                      )
                    }
                    color="primary"
                  />
                )}
                {correspondenceData?.importanceLevel && (
                  <Chip
                    sx={{ m: 0.5 }}
                    label={
                      i18n.language === "en"
                        ? correspondenceData?.importanceLevel.enName
                        : correspondenceData?.importanceLevel.arName
                    }
                    color={getImportanceChipColor(
                      correspondenceData?.importanceLevel.enName
                    )}
                  />
                )}
                <Chip
                  sx={{ m: 0.5 }}
                  label={
                    i18n.language === "en"
                      ? correspondenceData?.correspondenceTypeEnName
                      : correspondenceData?.correspondenceTypeArName
                  }
                  color="info"
                />
              </GridDX>
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
                <GridDX item xs={12}>
                  <TextFieldDX
                    readOnly
                    label={t("From")}
                    name="from"
                    value={
                      i18n.language === "en"
                        ? correspondenceData?.routedByUser?.enFullName
                        : correspondenceData?.routedByUser?.arFullName
                    }
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Subject")}
                    name="subject"
                    value={correspondenceData?.subject}
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Correspondence Date")}
                    name="date"
                    value={DateFormatter(
                      correspondenceData?.correspondenceDate,
                      userData?.calendarId,
                      userData?.gregorianDateFormatId,
                      userData?.hijriDateFormatId
                    )}
                    placeholder={t("No Information")}
                  />
                </GridDX>
              </GridDX>
              <Typography fontSize={20} sx={{ paddingTop: 2 }} gutterBottom>
                <FormatListBulletedOutlinedIcon fontSize="small" />{" "}
                {t("Reference Information")}
              </Typography>
              <Divider />
              <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Notes")}
                    name="notes"
                    value={correspondenceData?.notes}
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Description")}
                    name="description"
                    value={correspondenceData?.description}
                    placeholder={t("No Information")}
                  />
                </GridDX>
              </GridDX>
            </GridDX>
            <GridDX item xs={2}>
              {correspondenceData?.mainAttachmentId ? (
                <AttachmentBox
                  file={correspondenceData?.mainAttachmentURL}
                  data={correspondenceData}
                  removeFile={toRemoveFile}
                />
              ) : (
                <EmptyBox uploadFile={toUploadFile} />
              )}
            </GridDX>
          </GridDX>
        </CardContent>
        <CardActions>
          <GridDX container justifyContent="end">
            {(correspondenceData?.status === CorrespondenceStatus.Draft ||
              correspondenceData?.status === CorrespondenceStatus.Recall) &&
              correspondenceData.routedByUser?.id === userData.userId && (
                <ButtonDX onClick={() => setOpen(true)}>{t("Edit")}</ButtonDX>
              )}

            {correspondenceData?.status === CorrespondenceStatus.Pending &&
              action &&
              (action.requiredActionTypeId === 2 ? (
                <>
                  <LoadingButtonDX
                    sx={{ mx: 1 }}
                    color="success"
                    loading={isSaving}
                    loadingPosition="end"
                    onClick={() =>
                      handleOpenCorrespondenceConfirmModal(
                        onApproveCorrespondence,
                        "Approve"
                      )
                    }
                  >
                    {t("Approve")}
                  </LoadingButtonDX>
                  <LoadingButtonDX
                    sx={{ mx: 1 }}
                    color="error"
                    loading={isSaving}
                    loadingPosition="end"
                    // onClick={toRejectCorrespondence}
                    onClick={() =>
                      handleOpenCorrespondenceConfirmModal(
                        toRejectCorrespondence,
                        "Reject"
                      )
                    }
                  >
                    {t("Reject")}
                  </LoadingButtonDX>
                </>
              ) : (
                <LoadingButtonDX
                  sx={{ mx: 1 }}
                  color="success"
                  loading={isSaving}
                  loadingPosition="end"
                  // onClick={toReviewCorrespondence}

                  onClick={() =>
                    handleOpenCorrespondenceConfirmModal(
                      toReviewCorrespondence,
                      "Mark Reviewed"
                    )
                  }
                >
                  {t("Mark Reviewed")}
                </LoadingButtonDX>
              ))}

            {correspondenceData?.status === CorrespondenceStatus.Pending &&
              correspondenceData.routedByUser?.id === userData.userId && (
                <LoadingButtonDX
                  sx={{ mx: 1 }}
                  loading={isSaving}
                  loadingPosition="end"
                  onClick={() =>
                    handleOpenCorrespondenceConfirmModal(
                      toRecallCorrespondence,
                      "Recall"
                    )
                  }
                >
                  {t("Recall")}
                </LoadingButtonDX>
              )}
          </GridDX>
        </CardActions>
      </CardDX>

      <CardDX sx={{ mt: 2 }}>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <LegendToggleOutlinedIcon fontSize="medium" /> {t("Stats & Info")}
          </Typography>
          <Divider />
          <GridDX
            item
            xs={12}
            fullWidth
            justifyContent="space-around"
            sx={{ py: 2 }}
          >
            <BoxDX>
              <Typography
                fontSize={12}
                color="grey"
                sx={{ textAlign: "center" }}
              >
                {t("Current Destination")}
              </Typography>
              <Typography
                fontSize={20}
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {correspondenceStats?.currentDestinationUser
                  ? languageIsEn
                    ? correspondenceStats.currentDestinationUser.enFullName
                    : correspondenceStats.currentDestinationUser.arFullName
                  : "-"}
              </Typography>
            </BoxDX>
            <Divider orientation="vertical" flexItem />
            <BoxDX>
              <Typography
                fontSize={12}
                color="grey"
                sx={{ textAlign: "center" }}
              >
                {t("Last Update")}
              </Typography>
              <Typography
                fontSize={16}
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {(correspondenceStats?.lastUpdate &&
                  t(correspondenceStats?.lastUpdate?.split(":")[0]) +
                    ": " +
                    correspondenceStats?.lastUpdate?.split(":")[1]) ||
                  "-"}
              </Typography>
            </BoxDX>
          </GridDX>
          <Divider />
          <GridDX
            item
            xs={12}
            fullWidth
            justifyContent="space-around"
            sx={{ pt: 1 }}
          >
            <BoxDX>
              <Typography fontSize={12} color="grey">
                {t("No. of Days Since Creation")}
              </Typography>
              <Typography
                fontSize={30}
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {(correspondenceStats.daysSinceCreation &&
                  correspondenceStats.daysSinceCreation + " " + t("days")) ||
                  "-"}
              </Typography>
            </BoxDX>
            <Divider orientation="vertical" flexItem />
            <BoxDX>
              <Typography fontSize={12} color="grey">
                {t("Creation to Closing (Days)")}
              </Typography>
              <Typography
                fontSize={30}
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {(correspondenceStats.creationToClosingDays &&
                  correspondenceStats.creationToClosingDays +
                    " " +
                    t("days")) ||
                  "-"}
              </Typography>
            </BoxDX>
            <Divider orientation="vertical" flexItem />
            <BoxDX>
              <Typography fontSize={12} color="grey">
                {t("Total Attachments")}
              </Typography>
              <Typography
                fontSize={30}
                color="primary"
                sx={{ textAlign: "center" }}
              >
                {correspondenceStats.totalAttachments}
              </Typography>
            </BoxDX>
          </GridDX>
        </CardContent>
      </CardDX>

      <GridDX item xs={12} justifyContent="start">
        <CardDX sx={{ mt: 2, width: "100%" }}>
          <CardContent>
            <CorrespondenceTab
              status={correspondenceData?.status}
              hasWorkflow={correspondenceData?.routingGroup?.isWorkflow}
              getDetails={getDetails}
              correspondenceId={parseInt(id as string)}
              refetchStatusData={refetchStatusData}
              applyWatermark={correspondenceData?.applyWatermark}
            />
          </CardContent>
        </CardDX>
      </GridDX>
    </GridDX>
  );
};

export default CorrespondenceDetails;

const AttachmentBox = (props: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const { userData } = useAuthContext();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const fileName = `${props.data.correspondenceId}`;

  const downloadFile = async () => {
    const arrayBuffer = await fetch(props.file, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    }).then((res) => res.arrayBuffer());
    let fileData = arrayBuffer;
    if (props.data.applyWatermark)
      fileData = await addWatermarkToPDF(arrayBuffer, userData.enFullName);
    const blob = new Blob([fileData], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const toDelete = () => {
    props.removeFile();
    setDeleteOpen(false);
  };

  return (
    <CardDX
      sx={{
        flex: 1,
        ml: i18n.dir() === "ltr" ? 2 : 0,
        mr: i18n.dir() === "rtl" ? 2 : 0,
      }}
    >
      {deleteOpen && (
        <DeleteModal
          name={t("Attachments")}
          deleteMessage={t("You want to delete this attachment")}
          open={deleteOpen}
          onDeleteClick={toDelete}
          onCancelClick={() => setDeleteOpen(false)}
        />
      )}
      <CardContent>
        <GridDX
          fullWidth
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography>{t("Main Attachment")}</Typography>
          <InsertDriveFileOutlinedIcon sx={{ width: 100, height: 100 }} />
          <Typography>{fileName}</Typography>
        </GridDX>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", alignItems: "center" }}>
        <MenuButtonDX label={t("Actions")} sx={{ minWidth: 100 }}>
          <MenuItemDX
            onClick={() =>
              navigate("/attachment", {
                state: {
                  data: {
                    enName: `${props.data.correspondenceId}`,
                    arName: `${props.data.correspondenceId}`,
                    applyWatermark: props.data.applyWatermark,
                    mainAttachmentId: props.data.mainAttachmentId,
                    correspondenceId: props.data.correspondenceId,
                  },
                  file: props.file,
                  update: true,
                },
              })
            }
          >
            {t("View")}
          </MenuItemDX>
          <MenuItemDX onClick={downloadFile}>{t("Download")}</MenuItemDX>
          {(props.data.status === CorrespondenceStatus.Draft ||
            props.data.status === CorrespondenceStatus.Recall) &&
            props.data.routedByUser?.id === userData.userId && (
              <MenuItemDX onClick={() => setDeleteOpen(true)}>
                {t("Delete")}
              </MenuItemDX>
            )}
        </MenuButtonDX>
      </CardActions>
    </CardDX>
  );
};

const EmptyBox = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError } = useNotificationContext();

  const [openScan, setOpenScan] = useState(false);

  const onUpload = async (event: any) => {
    const file = event.target.files[0];
    if (file && fileTypeValidator(file)) {
      props.uploadFile(file);
    }
  };

  const onScan = async (fileData: any) => {
    const file = new File([fileData], "scannedDoc.pdf", {
      type: "application/pdf",
    });

    props.uploadFile(file);
  };

  const fileTypeValidator = (file: File) => {
    //function to reject exe, dll, and system files
    if (
      file.type === "" ||
      file.type === "application/x-msdownload" ||
      file.type === "application/octet-stream"
    ) {
      setError(t("File type not accepted"));
      return false;
    } else return true;
  };

  return (
    <CardDX
      sx={{
        flex: 1,
        ml: i18n.dir() === "ltr" ? 2 : 0,
        mr: i18n.dir() === "rtl" ? 2 : 0,
      }}
    >
      {openScan && (
        <ScanModal
          open={openScan}
          onClose={() => setOpenScan(false)}
          setFile={onScan}
        />
      )}
      <CardContent>
        <GridDX
          fullWidth
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography>{t("Main Attachment")}</Typography>
          <PriorityHighIcon sx={{ width: 100, height: 100 }} />
          <Typography>{t("No attachment found")}</Typography>
        </GridDX>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", alignItems: "center" }}>
        <MenuButtonDX
          label={t("Actions")}
          closeByDefault={false}
          sx={{ minWidth: 100 }}
        >
          <MenuItemDX component="label">
            {t("Upload")}
            <input type="file" hidden onChange={onUpload} />
          </MenuItemDX>
          <MenuItemDX onClick={() => setOpenScan(true)}>{t("Scan")}</MenuItemDX>
        </MenuButtonDX>
      </CardActions>
    </CardDX>
  );
};
