import { useTranslation } from "react-i18next";
import GridDX from "../../../layout/griddx";
import PieChartDx from "../../../business/piechart";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useReportService from "../../../../shared/services/reportservice";
import { useNotificationContext } from "../../../../context/notificationcontext";
import { GridColDef } from "@mui/x-data-grid";
import StaticDataGridDX from "../../../layout/staticdatagriddx";

import ButtonDX from "../../../controls/buttondx";

import { usePDF } from "react-to-pdf";
import Loading from "../../../loading";
import { downloadPDF, typeColors } from "../../../../shared/globals";
import FilterRange from "../../../controls/filterrange";

const CorrespondenceByTypes = (props: any) => {
  const { filterFromDate, filterToDate, isPdfGenerating, setIsPdfGenerating } =
    props;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { setError } = useNotificationContext();
  const { getCorrespondenceByTypeCount } = useReportService();
  const [rows, setRows] = useState<any>([]);
  const [dataForPieChart, setDataForPieChart] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.enName === "Total") return <strong>{t("Total")}</strong>
      },
    },
    {
      field: "count",
      headerName: `${t("Count")}`,
      flex: 1,
      renderCell(params) {
        if (params.row.enName === "Total") return <strong>{params.row.count}</strong>
      },
    },
  ];

  const getData = (formattedFromDate: any, formattedToDate: any) => {
    setIsLoading(true);
    getCorrespondenceByTypeCount(formattedFromDate, formattedToDate) //fetch user data from APi
      .then((res) => {
        let total = 0;
        let data = res.map((item: any, index: number) => {
          total += item.count;
          return {
            id: item.typeId,
            label: languageIsEn ? item.enName : item.arName,
            value: item.count,
          };
        });
        setDataForPieChart(data);

        setRows([
          ...res,
          { enName: "Total", arName: "المجموع", count: total, typeId: "total" },
        ]);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const APIdateFormater = (date: any) => {
    const thisDate = new Date(date);
    const year = date != null && thisDate?.getFullYear();
    const month =
      date != null && String(thisDate.getMonth() + 1).padStart(2, "0");
    const day = date != null && String(thisDate.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };
  useEffect(() => {
    let formattedFromDate =
      filterFromDate && filterFromDate != null
        ? APIdateFormater(filterFromDate)
        : null;
    let formattedToDate =
      filterToDate && filterToDate != null
        ? APIdateFormater(filterToDate)
        : null;
    if (formattedFromDate && formattedToDate) {
      getData(formattedFromDate, formattedToDate);
    }
  }, [i18n.language, filterFromDate, filterToDate]);

  const { toPDF, targetRef } = usePDF({
    filename: t("Correspondence By Types"),
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });

  return (
    <div>
      {isPdfGenerating && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <div ref={targetRef}>
        <GridDX container sx={{ marginBottom: 20, marginTop: 5, py: 2 }}>
          <GridDX item xs={12} sx={{ my: 1 }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold" }}
            >
              {t("Correspondence By Types")}
            </Typography>
            {!isPdfGenerating && (
              <ButtonDX
                onClick={() =>
                  downloadPDF({
                    toPDF,
                    setIsPdfGenerating,
                    fileName: t("Correspondence By Types"),
                  })
                }
              >
                {t("Download Report")}
              </ButtonDX>
            )}
            {isPdfGenerating && (
              <FilterRange
                filterFromDate={filterFromDate}
                filterToDate={filterToDate}
              />
            )}
          </GridDX>

          <GridDX
            item
            md={6}
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <PieChartDx
              colors={["#773dbd", "#B8B8B8", "#7A7A7A"]}
              data={dataForPieChart}
            />
          </GridDX>
          <GridDX item md={6} xs={12}>
            <StaticDataGridDX
              rows={rows}
              columns={columns}
              getRowId={(row: any) => row.typeId}
              isLoading={isLoading}
            />
          </GridDX>
        </GridDX>
      </div>
    </div>
  );
};

export default CorrespondenceByTypes;
