import { GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ListPageDX from "../../../components/business/listpagedx";
import { useNotificationContext } from "../../../context/notificationcontext";
import GridDX from "../../../components/layout/griddx";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import { DateFormatter, DateSorter } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import TypeTranslator from "../../../shared/typetranslator";

const ArchivedCorrespondenceList = () => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { getCorrespondenceStatusValue } = TypeTranslator();
  const { setError } = useNotificationContext();
  const { getArchivedCorrespondenceList } = useCorrespondenceService();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const [correspondenceData, setCorrespondenceData] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [i18n.language]);

  const columns: GridColDef[] = [
    {
      field: "correspondenceId",
      headerName: `${t("ID")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      hideable: true,
    },
    {
      field: "subject",
      headerName: `${t("Subject")}`,

      headerAlign: "center",
      minWidth: 110,
    },
    {
      field: "correspondenceDate",
      headerName: `${t("Date")}`,

      align: "center",
      headerAlign: "center",
      sortComparator: DateSorter,
      minWidth: 100,
    },
    {
      field: languageIsEn
        ? "correspondenceTypeEnName"
        : "correspondenceTypeArName",
      headerName: `${t("Type")}`,

      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "importanceLevel",
      headerName: `${t("Importance Level")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      valueGetter(params) {
        return languageIsEn
          ? params.row.importanceLevel?.enName
          : params.row.importanceLevel?.arName;
      },
    },
    {
      field: "privacyLevel",
      headerName: `${t("Privacy Level")}`,
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      valueGetter(params) {
        return languageIsEn
          ? params.row.privacyLevel?.enName
          : params.row.privacyLevel?.arName;
      },
    },
    {
      field: "domainType",
      headerName: `${t("Domain")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      valueGetter(params) {
        return languageIsEn
          ? params.row.domainType?.enName
          : params.row.domainType?.arName;
      },
    },

    {
      field: languageIsEn ? "entityNameEnName" : "entityNameArName",
      headerName: `${t("From (Entity)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "routedByUser",
      headerName: `${t("From (User)")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      valueGetter(params) {
        return i18n.language === "en"
          ? params.row.routedByUser?.enFullName
          : params.row.routedByUser?.arFullName;
      },
    },
    {
      field: languageIsEn ? "enDestination" : "arDestination",
      headerName: `${t("Current Destination")}`,
      flex: 1,
      headerAlign: "center",
      minWidth: 100,
    },
    {
      field: "modifiedDate",
      headerName: `${t("Last modified at")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      sortComparator: DateSorter,
    },
  ];

  const getData = () => {
    setIsLoading(true);
    getArchivedCorrespondenceList()
      .then((res) => {
        const preprocessedData = res.map((data: any) => ({
          ...data,
          status: getCorrespondenceStatusValue(data.status), // Override the status field,
          correspondenceDate: DateFormatter(
            data.correspondenceDate,
            calendarId,
            gregorianDateFormatId,
            hijriDateFormatId
          ),
        }));
        setRows(preprocessedData);
        setCorrespondenceData(preprocessedData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    // if (!value.trim()) {
    //   setRows(correspondenceData);
    //   return;
    // }
    const lowercasedValue = value.toLowerCase();
    const newRows = correspondenceData?.filter(
      ({
        correspondenceId,
        subject,
        correspondenceTypeEnName,
        correspondenceTypeArName,
        entityNameEnName,
        entityNameArName,
        enDestination,
        arDestination,
        routedByUser,
        correspondenceDate,
        status,
        contentAsText,
      }: any) =>
        languageIsEn
          ? correspondenceId?.toString().includes(value) ||
            subject?.toLowerCase().includes(lowercasedValue) ||
            routedByUser?.enFullName?.toLowerCase().includes(lowercasedValue) ||
            correspondenceTypeEnName?.toLowerCase().includes(lowercasedValue) ||
            entityNameEnName?.toLowerCase().includes(lowercasedValue) ||
            enDestination?.toLowerCase().includes(lowercasedValue) ||
            status?.toLowerCase().includes(lowercasedValue) ||
            correspondenceDate?.toLowerCase().includes(lowercasedValue) ||
            contentAsText?.toLowerCase().includes(lowercasedValue)
          : correspondenceId?.toString().includes(value) ||
            subject?.toLowerCase().includes(value) ||
            routedByUser?.arFullName?.includes(value) ||
            correspondenceTypeArName?.includes(value) ||
            entityNameArName?.includes(value) ||
            arDestination?.includes(value) ||
            status?.includes(value) ||
            correspondenceDate?.toLowerCase().includes(lowercasedValue) ||
            contentAsText?.toLowerCase().includes(value)
    );
    setRows(newRows);
  };

  return (
    <GridDX
      container
      sx={{ width: "100%", height: "100%", my: 1 }}
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
    >
      <GridDX item xs={12}>
        <ListPageDX
          listTitle={t("Archived Correspondence List")}
          name="ArchivedCorrespondence"
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.correspondenceId}
          isLoading={isLoading}
          setGridFilterCriteria={setGridFilterCriteria}
          hideColumnsOption={true}
        />
      </GridDX>
    </GridDX>
  );
};

export default ArchivedCorrespondenceList;
