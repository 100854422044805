import { NormalizedSelection, PdfViewer } from "react-pdf-selection";
import AddEditModalDX from "../business/addeditmodaldx";
import Loading from "../loading";
import { useTranslation } from "react-i18next";
import { useNotificationContext } from "../../context/notificationcontext";
import { useEffect, useState } from "react";
import { convertToTwoDigits } from "../../shared/globals";
import { addSignatureToPDF } from "../../shared/pdfoperations";
import useCorrespondenceActionService from "../../shared/services/correspondenceactionservice";
import useUserService from "../../shared/services/userservices";
import { useAuthContext } from "../../context/authcontext";
import { Typography } from "@mui/material";
import OTPModal from "../business/otpmodal";
import useMainAttachmentService from "../../shared/services/mainattachmentservice";

const SignatureModal = (props: any) => {
  const { t } = useTranslation();
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { updateMainAttachment } = useMainAttachmentService();
  const { getUserById } = useUserService();
  const { sendOTP } = useCorrespondenceActionService();

  const [isLoading, setIsLoading] = useState(false);
  const [pdf, setPDF] = useState<any>(null);
  const [openOTP, setOpenOTP] = useState(false);
  const [signature, setSignature] = useState<any>("");
  const [position, setPosition] = useState<any>({});
  let dims = { x: 10, y: 10, page: 1 };

  useEffect(() => {
    loadPDF();
    getSignature();
  }, []);

  const loadPDF = async () => {
    setIsLoading(true);
    fetch(props.data.mainAttachmentURL, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    })
      .then((res) => {
        res.arrayBuffer().then(async (attachment: any) => {
          const blob = new Blob([attachment], { type: "application/pdf" });
          const link = URL.createObjectURL(blob);
          setPDF(link);
        });
      })
      .catch((err) => {
        setError(err);
        handleClose();
      })
      .finally(() => setIsLoading(false));
  };

  const showRect = (highlightTip?: NormalizedSelection) => {
    dims = {
      x: highlightTip?.position.absolute.boundingRect.left as number,
      y: highlightTip?.position.absolute.boundingRect.bottom as number,
      page: highlightTip?.position.pageNumber as number,
    };
    console.log(
      highlightTip
        ? `New ${"image" in highlightTip ? "area" : "text"} selection`
        : "Reset selection",
      highlightTip?.position
    );
  };

  const handleClose = () => {
    setOpenOTP(false);
    props.onClose();
  };

  const getOTP = async () => {
    sendOTP(props.data.correspondenceId)
      .then(() => {
        setPosition(dims);
        setOpenOTP(true);
      })
      .catch((err) => setError(err));
  };

  const getSignature = async () => {
    getUserById(userData.userId)
      .then((res) => {
        if (res.signatureImage === "") {
          setIsLoading(false);
          setError(t("No signature uploaded!"));
          setSignature(null);
        } else setSignature(res.signatureImage);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const addSignature = async () => {
    setIsLoading(true);

    if (signature) {
      var currentdate = new Date();
      var timeStamp =
        "Signed at: " +
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        convertToTwoDigits(currentdate.getHours()) +
        ":" +
        convertToTwoDigits(currentdate.getMinutes()) +
        ":" +
        convertToTwoDigits(currentdate.getSeconds());

      const type = signature.split(";")[0].split("/").pop();

      fetch(props.data.mainAttachmentURL, {
        headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
      })
        .then((res) => {
          res.arrayBuffer().then(async (attachment: any) => {
            const updatedFile = await addSignatureToPDF(
              attachment,
              signature,
              type,
              timeStamp,
              { x: position.x, y: position.y, scale: 1, page: position.page }
            );
            const blob = new Blob([updatedFile], { type: "application/pdf" });
            const file = new File([blob], "updatedDoc.pdf", {
              type: "application/pdf",
            });

            const formData = new FormData();
            formData.append(
              "ExistingMainAttachmentId",
              props.data.mainAttachmentId
            );
            formData.append("CorrespondenceId", props.data.correspondenceId);
            formData.append("MainAttachmentFile", file);

            setIsLoading(true);
            updateMainAttachment(formData)
              .then((result) => {
                props.toApprove();
                handleClose();
              })
              .catch((err) => setError(err));
          });
        })
        .catch((err) => setError(err));
    } else {
      setIsLoading(false);
      setError(t("No signature found"));
      handleClose();
    }
  };

  const Viewer = ({ document }: any) => {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "100%" }}>{document}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loading styles={{ height: "100%", width: "100%" }} />
      ) : (
        <AddEditModalDX
          open={props.open}
          handleClose={handleClose}
          isSaving={isLoading}
          title={t("Add Signature")}
          maxWidth="md"
          saveLabel={t("Add")}
          onSaveClick={getOTP}
          onYesClick={handleClose}
        >
          {openOTP && (
            <OTPModal
              open={openOTP}
              correspondenceId={props.data.correspondenceId}
              onClose={() => setOpenOTP(false)}
              toSave={addSignature}
            />
          )}
          <Typography fontSize={14} textAlign={"center"}>
            {t("Select the area where you need to put the signature")}
          </Typography>
          <PdfViewer
            url={pdf}
            enableAreaSelection={() => true}
            scale={1}
            onAreaSelection={showRect}
            onLoad={(dims) => console.log(dims)}
          >
            {({ document }) => <Viewer document={document} />}
          </PdfViewer>
        </AddEditModalDX>
      )}
    </>
  );
};

export default SignatureModal;
