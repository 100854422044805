import CardDX from "./carddx";
import { CardContent, CircularProgress, Typography } from "@mui/material";
import BoxDX from "./boxdx";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useTranslation } from "react-i18next";

const ChartBox = (props: any) => {
  const { i18n } = useTranslation();
  const colors = props.colors ?? ["#6750A4", "#c0c0c0"];

  return (
    <CardDX
      sx={{
        width: "100%",
        height: 160,
        my: 1,
        borderColor: "transparent",
        borderRadius: "8px",
        filter: "drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25))",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          p: 0,
          "&:last-child": {
            pb: 1,
          },
          width: "100%",
        }}
      >
        <BoxDX
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!props.loading ? (
            <PieChart
              colors={colors}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: "12px",
                },
                direction: "ltr",
                "--ChartsLegend-rootOffsetX": "5px",
                "--ChartsLegend-rootOffsetY": "0px",
                "--ChartsLegend-itemMarkSize": "7px",
                "--ChartsLegend-rootSpacing": "8px",
              }}
              series={[
                {
                  arcLabel: (item) => `${item.value}`,
                  data: props.data,
                  innerRadius: 0,
                  outerRadius: 55,
                  arcLabelMinAngle: 10,
                },
              ]}
              width={210}
              height={130}
            />
          ) : (
            <CircularProgress />
          )}
        </BoxDX>

        <Typography
          sx={{
            fontSize: 18,
            lineHeight: "20px",
            textAlign: "center",
          }}
          color="#625B71"
        >
          {props.title}
        </Typography>
      </CardContent>
    </CardDX>
  );
};

export default ChartBox;
