import { useEffect, useState } from "react";
import { Drawer } from "@mui/material";

import MenuItems from "./menuitems";

const MobileMenu = (props: any) => {
  const [mobileOpen, setMobileOpen] = useState(props?.open ?? false);

  useEffect(() => {
    if (props.open) setMobileOpen(props.open);
  }, [props?.open]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);

    if (mobileOpen) props?.closeMenu();
  };

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          backgroundColor: "#773dbd"
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "100%",
          top: 65, //toolbar height
        },
      }}
    >
      <MenuItems open={true} onClick={handleDrawerToggle} />
    </Drawer>
  );
};

export default MobileMenu;
