import { useEffect, useState } from "react";
import AddEditModalDX from "../../business/addeditmodaldx";
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import SelectListDX from "../../controls/selectlistdx";
import Loading from "../../loading";
import useFolderService from "../../../shared/services/folderservice";
import useFolderTemplateService from "../../../shared/services/foldertemplateservice";
import useDocumentProfileService from "../../../shared/services/documentprofileservice";
import useDocumentCategoryTypeService from "../../../shared/services/documentcategory";
import useDocumentTypeService from "../../../shared/services/documenttypeservice";
import AutoCompleteMultiple from "../../controls/autocompletemultiple";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import moment from "moment";
import DatePickerDX from "../../controls/datepickerdx";
import BoxDX from "../../layout/boxdx";
import { useAuthContext } from "../../../context/authcontext";

const DirectoryModal = (props: any) => {
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const { open, handleClose, directory, cabinetId, folderId } = props;
  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const { setError, setInfo } = useNotificationContext();
  const { addFolder, updateFolder } = useFolderService();
  const { getFolderTemplates } = useFolderTemplateService();
  const { getDocumentTypes } = useDocumentTypeService();
  const { getDocumentCategoryTypes } = useDocumentCategoryTypeService();
  const { getDocumentProfiles, getDocumentProfileByID } =
    useDocumentProfileService();

  const defaultValues = {
    enName: "",
    arName: "",
    folderTemplateId: null,
    cabinetId: cabinetId,
    parentFolderId: folderId ?? null,
    documentTypeId: "",
    documentCategories: [],
    documentProfileInFolders: [],
    profileData: null,
    physicalLocation: ""
  };

  const [directoryData, setDirectoryData] = useState<any>(defaultValues);
  const [structureTemplateList, setStructureTemplateList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [docTypes, setDocTypes] = useState<any>([]);
  const [docCategories, setDocCategories] = useState<any>([]);
  const [docProfiles, setDocProfiles] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [tab, setTab] = useState("0");
  const [selectedDocumentProfiles, setSelectedDocumentProfiles] = useState<any>([]);

  useEffect(() => {
    populateLists();
  }, []);

  useEffect(()=>{
    if (directory) {
      setDirectoryData(directory);
    };
  }, [directory])

  useEffect(() => {
    //check if the selected profile contains a profile that is not present in data then remove it from selectedDocumentProfiles as well
    let selectedProfiles = directoryData?.documentProfileInFolders.map(
      (profile: any) => {
        return profile.value;
      }
    );
    let newSelectedProfiles = selectedDocumentProfiles.filter((profile: any) =>
      selectedProfiles.includes(profile.documentProfileId)
    );
    setSelectedDocumentProfiles(newSelectedProfiles);

    directoryData?.documentProfileInFolders?.forEach((profile: any) => {
      // call the api to get the profile data only if the profile is not already present in the selectedDocumentProfiles
      if (
        !selectedDocumentProfiles.some(
          (selectedProfile: any) =>
            selectedProfile.documentProfileId === profile.value
        )
      ) {
        getDocumentProfileByID(profile.value).then((response) => {
          setSelectedDocumentProfiles((prev: any) => [...prev, response]);
        });
      }
    });
  }, [directoryData?.documentProfileInFolders.length]);

  useEffect(() => {
    if (selectedDocumentProfiles?.length > 0) {
      let profileData = directoryData.profileData || {};
      selectedDocumentProfiles.forEach((profile: any) => {
        // check if the profile is already present in the profileData if not then add it
        //@ts-ignore
        if (!profileData[profile.documentProfileId]) {
          let profileObj = {};
          profile?.documentProfileFields?.forEach((field: any) => {
            //@ts-ignore
            profileObj[field.enName] = ""; // Set whatever value you need here
          });
          //@ts-ignore
          profileData[profile.documentProfileId] = profileObj;
        }
      });

      setDirectoryData((prev: any) => ({
        ...prev,
        profileData: profileData,
      }));
    }
  }, [selectedDocumentProfiles]);



  const populateLists = async () => {
    const p1 = getDocumentTypes();
    const p2 = getDocumentCategoryTypes();
    const p3 = getDocumentProfiles();
    const p4 = getFolderTemplates();

    setIsLoading(true);
    Promise.all([p1, p2, p3, p4])
      .then(([types, categories, profiles, templates]) => {
        setDocTypes(types);
        setDocCategories(categories);
        setDocProfiles(profiles);
        setStructureTemplateList(templates);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (directoryData.enName.length === 0)
      newErrors["enName"] = t("English name is required");
    if (directoryData.arName.length === 0)
      newErrors["arName"] = t("Arabic name is required");
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      if (directory) {
        updateFolder(directoryData)
          .then((res: any) => {
            setInfo(t("Directory updated successfully"));
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsLoading(false));
        
      }
      else {
        addFolder(directoryData)
          .then((res: any) => {
            setInfo(t("Directory added successfully"));
            handleClose(true);
          })
          .catch((err: any) => setError(err))
          .finally(() => setIsLoading(false));
      }
    }
  };

  const handleTabChange = (event: any, newValue: any) => setTab(newValue);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setDirectoryData({
      ...directoryData,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  
  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={directory ? t("Edit Directory") : t("New Directory")}
      maxWidth="md"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={directoryData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={directoryData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Physical Location")}
            name="physicalLocation"
            value={directoryData.physicalLocation}
            onChange={handleInputChange}
            error={errors["physicalLocation"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <SelectListDX
            label={t("Document Type")}
            items={docTypes.map((type: any) => {
              return {
                value: type.documentTypeId,
                text: languageIsEn ? type.enName : type.arName,
              };
            })}
            name="documentTypeId"
            value={directoryData.documentTypeId}
            onChange={handleInputChange}
            disabled={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Document Categories")}
            name="categories"
            value={directoryData.documentCategories}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setDirectoryData({
                ...directoryData,
                documentCategories: newValue,
              });
            }}
            list={docCategories.map((type: any) => {
              return {
                value: type.documentCategoryTypeId,
                text: languageIsEn ? type.enName : type.arName,
              };
            })}
            disabled={isLoading}
          />
        </GridDX>
        <GridDX item xs={12}>
          <SelectListDX
            label={`${t("Structure Template")}`}
            items={structureTemplateList.map((template: any) => {
              return {
                value: template.folderTemplateId,
                text: languageIsEn ? template.enName : template.arName,
              };
            })}
            name="folderTemplateId"
            onChange={handleInputChange}
            value={directoryData?.folderTemplateId ?? ""}
          />
        </GridDX>
        <GridDX item xs={12}>
          <AutoCompleteMultiple
            sx={{ width: "100%" }}
            label={t("Document Profiles")}
            name="documentProfileInDocuments"
            value={directoryData.documentProfileInFolders}
            onChange={(newValue: any) => {
              setIsChanged(true);
              setDirectoryData({
                ...directoryData,
                documentProfileInFolders: newValue,
              });
              //smooth scroll to bottom
              window.scrollTo({
                top: document.body.scrollHeight,
                behavior: "smooth",
              });
            }}
            list={docProfiles.map((type: any) => {
              return {
                value: type.documentProfileId,
                text: languageIsEn ? type.enName : type.arName,
              };
            })}
            disabled={isLoading}
          />
        </GridDX>

        {/* tabs for each profile selected */}
        {selectedDocumentProfiles?.length > 0 && (
          <GridDX item xs={12} sx={{ color: "black", flexWrap: "wrap" }}>
            <TabContext value={tab}>
              <BoxDX
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  {selectedDocumentProfiles.map(
                    (profile: any, index: number) => (
                      <Tab
                        key={index}
                        label={profile.enName}
                        value={index.toString()}
                      />
                    )
                  )}
                </TabList>
              </BoxDX>
              <BoxDX sx={{ width: "100%" }}>
                {selectedDocumentProfiles?.map(
                  (profile: any, index: number) => (
                    <TabPanel key={index} value={index.toString()}>
                      <GridDX
                        container
                        rowSpacing={1}
                        columnSpacing={2}
                        sx={{ padding: 0 }}
                      >
                        {profile?.documentProfileFields?.map(
                          (field: any, index: number) => (
                            <GridDX item key={index} xs={6}>
                              {field.fieldDataType === 1 ? 
                              <DatePickerDX
                                label={field.enName}
                                name={field.enName}
                                sx={{ flex: 1 }}
                                value={moment(
                                  directoryData.profileData?.[
                                    profile.documentProfileId
                                  ]?.[field.enName] || ""
                                )}
                                calendarId={calendarId}
                                onChange={(value: any) => {
                                  setIsChanged(true);
                                  setDirectoryData((prev: any) => {
                                    let newProfileData = {
                                      ...prev.profileData,
                                    };
                                    newProfileData[profile.documentProfileId][
                                      field.enName
                                    ] = value;
                                    return {
                                      ...prev,
                                      profileData: newProfileData,
                                    };
                                  });
                                }}
                              />
                              : 
                              <TextFieldDX
                                label={field.enName}
                                name={field.enName}
                                // profileData should be used here
                                value={
                                  directoryData.profileData?.[
                                    profile.documentProfileId
                                  ]?.[field.enName] || ""
                                }
                                onChange={(e: any) => {
                                  setIsChanged(true);
                                  setDirectoryData((prev: any) => {
                                    let newProfileData = {
                                      ...prev.profileData,
                                    };
                                    newProfileData[profile.documentProfileId][
                                      field.enName
                                    ] = e.target.value;
                                    return {
                                      ...prev,
                                      profileData: newProfileData,
                                    };
                                  });
                                }}
                              />}
                            </GridDX>
                          )
                        )}
                      </GridDX>
                    </TabPanel>
                  )
                )}
              </BoxDX>
            </TabContext>
          </GridDX>
        )}
      </GridDX>
    </AddEditModalDX>
  );
};

export default DirectoryModal;
