import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import ListPageDX from "../../../business/listpagedx";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GridDX from "../../../layout/griddx";
import ButtonDX from "../../../controls/buttondx";
import LoadingButtonDX from "../../../controls/loadingbuttondx";
import { getContentFromPdf } from "../../../../shared/pdfoperations";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useDocumentVersionService from "../../../../shared/services/documentversionservice";
import {
  DateSorter,
  DateTimeFormatter,
  getMIMEType,
  singleImageOCR,
} from "../../../../shared/globals";
import { Delete, Download, Preview, Task } from "@mui/icons-material";
import DeleteModal from "../../../controls/deleteModal";
import { useAuthContext } from "../../../../context/authcontext";
import VersionViewerModal from "../../../editmodals/DMS/versionviewermodal";
import ScanModal from "../../../editmodals/scanmodal";

const FileVersions = (props: any) => {
  const { t, i18n } = useTranslation();
  const { setError, setInfo } = useNotificationContext();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const {
    getVersionsByDocumentID,
    addDocumentVersion,
    markAsMainVersion,
    deleteDocumentVersion,
  } = useDocumentVersionService();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
      valueGetter(params) {
        return i18n.language === "en"
          ? props.enFileName ?? ""
          : props.arFileName ?? "";
      },
    },
    {
      field: "creator",
      headerName: `${t("Created By")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: any) => {
        return i18n.language === "en"
          ? params.row.createdByUser.enFullName
          : params.row.createdByUser.arFullName;
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Created At")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortComparator: DateSorter,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
    },
    {
      field: "isMainVersion",
      headerName: `${t("Main Version")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter(params) {
        return params.row.isMainVersion ? "Yes" : "No";
      },
    },
    {
      field: "versionNumber",
      headerName: `${t("Version Number")}`,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  const buildActions = (params: GridRowParams) => {
    let actionsArray = [
      <GridActionsCellItem
        label={t("Preview")}
        showInMenu
        onClick={() => {
          setFile(params.row);
          setOpenViewer(true);
        }}
        icon={<Preview />}
      />,
      <GridActionsCellItem
        label={t("Download")}
        showInMenu
        onClick={() => toDownload(params.row)}
        icon={<Download />}
      />,
    ];

    if (!params.row.isMainVersion) {
      actionsArray.push(
        <GridActionsCellItem
          label={t("Set as Main Version")}
          showInMenu
          onClick={() => setAsMainVersion(params.row.documentVersionId)}
          icon={<Task />}
        />,
        <GridActionsCellItem
          label={t("Delete")}
          showInMenu
          onClick={() => setDeleteModal({ open: true, id: params.id })}
          icon={<Delete />}
        />
      );
    }

    return actionsArray;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [rows, setRows] = useState([]);
  const [versions, setVersions] = useState<any>([]);
  const [deleteModal, setDeleteModal] = useState<any>({
    open: false,
    id: null,
  });
  const [openViewer, setOpenViewer] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    getVersionsByDocumentID(props.documentId)
      .then((response) => {
        setRows(response);
        setVersions(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const fileTypeValidator = (file: File) => {
    //function to reject exe, dll, and system files
    if (
      file.type === "" ||
      file.type === "application/x-msdownload" ||
      file.type === "application/octet-stream"
    ) {
      setError(t("File type not accepted"));
      return false;
    } else return true;
  };

  const onScan = async (file: any) => {
    setIsSaving(true);
    const fileData = new File([file], "scannedDoc.pdf", {
      type: "application/pdf",
    });

    const formData = new FormData();
    formData.append("DocumentId", props.documentId);
    formData.append("File", fileData);

    getContentFromPdf(fileData)
      .then((content) => {
        formData.append("ContentAsText", content);
        saveDocument(formData);
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const onUpload = async (event: any) => {
    const file: File = event.target.files[0];
    if (fileTypeValidator(file)) {
      setIsSaving(true);
      const formData = new FormData();
      const fileType = file.type.split("/");
      formData.append("DocumentId", props.documentId);
      formData.append("File", file);

      if (fileType[1] === "pdf") {
        getContentFromPdf(file)
          .then((content) => {
            formData.append("ContentAsText", content);
            saveDocument(formData);
          })
          .catch((err) => {
            setError(err);
            setIsSaving(false);
          });
      } else if (fileType[0] === "image") {
        singleImageOCR(file)
          .then((content) => {
            formData.append("ContentAsText", content);
            saveDocument(formData);
          })
          .catch((err) => {
            setError(err);
            setIsSaving(false);
          });
      } else {
        formData.append("ContentAsText", " ");
        saveDocument(formData);
      }
    }
  };

  const saveDocument = (document: any) => {
    addDocumentVersion(document)
      .then(() => {
        setInfo(t("Document added successfully"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsSaving(false));
  };

  const setAsMainVersion = (id: number) => {
    setIsLoading(true);
    markAsMainVersion(id)
      .then((res) => {
        setInfo(t("Document set as main version"));
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toDownload = (doc: any) => {
    fetch(doc.downloadURL, {
      headers: { Authorization: `Bearer ${userData.tokenInfo.token}` },
    })
      .then((res) => {
        res.arrayBuffer().then((file) => {
          const blob = new Blob([file], {
            type: getMIMEType(doc.fileExtension),
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", props.enFileName);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: any) => {
    setIsLoading(true);
    deleteDocumentVersion(id)
      .then(() => {
        setInfo(t("Document deleted successfully"));
        setDeleteModal({ open: false, id: null });
        getData();
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(versions);
      return;
    }

    const lowercasedValue = value.toLowerCase();
    const newRows = versions.filter(({ versionNumber, createdByUser }: any) =>
      i18n.language === "en"
        ? createdByUser.enFullName.toLowerCase().includes(lowercasedValue) ||
          versionNumber.toString() === lowercasedValue
        : createdByUser.arFullName.includes(value) ||
          versionNumber.toString() === lowercasedValue
    );
    setRows(newRows);
  };

  const handleClose = (refreshPage: boolean = false) => {
    setOpenViewer(false);
    setOpenScanner(false);

    if (refreshPage) getData();
  };

  return (
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {openScanner && (
        <ScanModal
          open={openScanner}
          onClose={() => setOpenScanner(false)}
          setFile={onScan}
        />
      )}
      <GridDX justifyContent="end">
        <LoadingButtonDX
          variant="contained"
          component="label"
          sx={{ mx: 2 }}
          loading={isSaving}
        >
          {t("Upload")}
          <input type="file" hidden onChange={onUpload} />
        </LoadingButtonDX>
        <LoadingButtonDX
          onClick={() => setOpenScanner(true)}
          loading={isSaving}
        >
          {t("Scan")}
        </LoadingButtonDX>
      </GridDX>
      <GridDX>
        {openViewer && (
          <VersionViewerModal
            open={openViewer}
            handleClose={handleClose}
            fileName={
              i18n.language === "en"
                ? props.enFileName ?? ""
                : props.arFileName ?? ""
            }
            document={file}
          />
        )}
        {deleteModal.open && (
          <DeleteModal
            open={deleteModal}
            onDeleteClick={() => onDelete(deleteModal.id)}
            onCancelClick={() => setDeleteModal({ open: false, id: null })}
          />
        )}
        <ListPageDX
          listTitle={t("File Versions")}
          name={t("File Versions")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.documentVersionId}
          isLoading={isLoading}
          exportToPDF={false}
          exportToCSV={false}
          setGridFilterCriteria={setGridFilterCriteria}
          buildActions={buildActions}
        />
      </GridDX>
    </GridDX>
  );
};

export default FileVersions;
