import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import { useEffect, useState } from "react";
import TextEditorDX from "../../business/texteditordx";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import useDocumentTemplateService from "../../../shared/services/documenttemplateservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import draftToHtml from "draftjs-to-html";

const DocumentTemplateModal = (props: any) => {
  const { open, handleClose, documentTemplateData } = props;
  const { t } = useTranslation();
  const { updateDocumentTemplate, addDocumentTemplate } =
    useDocumentTemplateService();
  const { setError, setInfo } = useNotificationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const defaultValues = {
    documentTemplateId: 0,
    enName: "",
    arName: "",
    content: "",
  };

  const [templateData, setTemplateData] = useState(defaultValues);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (documentTemplateData) {
      setTemplateData(documentTemplateData);
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(documentTemplateData.content).contentBlocks // Assuming you have a property named 'content' for each document
      );
      const editorStateFromHtml = EditorState.createWithContent(contentState);
      setEditorState(editorStateFromHtml);
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (templateData.enName.length === 0)
      newErrors["enName"] = t("This field is required");
    if (templateData.arName.length === 0)
      newErrors["arName"] = t("This field is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const updatedData = {
        ...templateData,
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      };
      documentTemplateData
        ? updateDocumentTemplate(updatedData)
            .then(() => {
              setInfo(t("Document template updated successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false))
        : addDocumentTemplate(updatedData)
            .then((res) => {
              setInfo(t("Document template created successfully"));
              handleClose(true);
            })
            .catch((err) => setError(err))
            .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setTemplateData({
      ...templateData,
      [name]: value,
    });
  };

  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        documentTemplateData
          ? t("Edit Document Template")
          : t("Create Document Template")
      }
      maxWidth="full"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("English Name")}
            name="enName"
            value={templateData.enName}
            onChange={handleInputChange}
            error={errors["enName"]}
          />
        </GridDX>
        <GridDX item xs={6}>
          <TextFieldDX
            label={t("Arabic Name")}
            name="arName"
            value={templateData.arName}
            onChange={handleInputChange}
            error={errors["arName"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextEditorDX
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default DocumentTemplateModal;
