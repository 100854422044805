import moment, { Moment } from "moment";
import momentHijri from "moment-hijri";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context/authcontext";
import { useNotificationContext } from "../../../context/notificationcontext";
import useCorrespondenceService from "../../../shared/services/correspondenceservice";
import useCorrespondenceTypeService from "../../../shared/services/correspondencetypes";
import useDeliveryService from "../../../shared/services/deliverytypeservice";
import useDomainTypeService from "../../../shared/services/domaintypeservice";
import useImportanceLevelsService from "../../../shared/services/importancelevelsservice";
import usePrivacyLevelsService from "../../../shared/services/privacylevelsservice";
import DatePickerDX from "../../controls/datepickerdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ButtonDX from "../../controls/buttondx";
import ConfirmModal from "../../controls/confirmmodal";
import LoadingButtonDX from "../../controls/loadingbuttondx";
import { CorrespondenceStatus } from "../../../shared/globals";
import CheckBoxDX from "../../controls/checkboxdx";

const EditCorrespondenceModal = (props: any) => {
  const { t, i18n } = useTranslation();

  const { userData } = useAuthContext();
  const { calendarId } = userData;
  const languageIsEn = i18n.language === "en";
  const { setError, setInfo } = useNotificationContext();
  const { updateCorrespondence } = useCorrespondenceService();
  const { getCorrespondenceType } = useCorrespondenceTypeService();
  const { getImportanceLevels } = useImportanceLevelsService();
  const { getPrivacyLevels } = usePrivacyLevelsService();
  const { getDomainTypes } = useDomainTypeService();
  const { getDeliveryTypes } = useDeliveryService();

  useEffect(() => {
    populateLists();
  }, []);

  const getDate = (date: Moment) => {
    return calendarId === 1 ? moment(date) : momentHijri(date);
  };

  const [correspondenceData, setCorrespondenceData] = useState({
    ...props.correspondenceData,
    correspondenceDate: getDate(props.correspondenceData.correspondenceDate),
    dueDate:
      props.correspondenceData.dueDate !== null
        ? getDate(props.correspondenceData.dueDate)
        : null,
  });
  const [date, setDate] = useState(correspondenceData.correspondenceDate);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [correspondenceTypeList, setCorrespondenceTypeList] = useState([]);
  const [importanceLevelList, setImportanceLevelList] = useState([]);
  const [privacyLevelList, setPrivacyLevelList] = useState([]);
  const [domainTypeList, setDomainTypeList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);

  const populateLists = async () => {
    setIsLoading(true);
    const p1 = getCorrespondenceType();
    const p2 = getDomainTypes();
    const p3 = getImportanceLevels();
    const p4 = getPrivacyLevels();
    const p5 = getDeliveryTypes();

    Promise.all([p1, p2, p3, p4, p5])
      .then(
        ([
          correspondenceTypes,
          domainTypes,
          importanceLevels,
          privacyLevels,
          deliveryTypes,
        ]) => {
          setCorrespondenceTypeList(correspondenceTypes);
          setDomainTypeList(domainTypes);
          setImportanceLevelList(importanceLevels);
          setPrivacyLevelList(privacyLevels);
          setDeliveryList(deliveryTypes);
        }
      )
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (correspondenceData.subject.length === 0)
      newErrors["subject"] = t("Subject is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = (status: CorrespondenceStatus) => {
    if (validateForm()) {
      setIsSaving(true);
      const data = {
        ...correspondenceData,
        status: status,
      };

      updateCorrespondence(data)
        .then((response) => {
          setInfo(t("Correspondence updated successfully"));
          props.handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setIsChanged(true);
    setCorrespondenceData({
      ...correspondenceData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    if (name === "correspondenceDate") setDate(value);
    setIsChanged(true);
    setCorrespondenceData({
      ...correspondenceData,
      [name]: value,
    });
  };

  const onClose = () => {
    if (isChanged) setOpen(true);
    else props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      fullWidth
      maxWidth={"xl"}
      disableEscapeKeyDown
      sx={{ direction: i18n.dir() }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("Edit Correspondence")}
      </DialogTitle>
      <DialogContent>
        {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
        {open && (
          <ConfirmModal
            open={open}
            onYesClick={props.handleClose}
            onNoClick={() => setOpen(false)}
          />
        )}
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={12}>
            <SelectListDX
              label={t("Correspondence Type")}
              items={correspondenceTypeList.map((type: any) => {
                return {
                  value: type.correspondenceTypeId,
                  text: languageIsEn ? type.enName : type.arName,
                };
              })}
              name="correspondenceTypeId"
              value={correspondenceData.correspondenceTypeId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Subject")}
              name="subject"
              value={correspondenceData.subject}
              onChange={handleInputChange}
              error={errors["subject"]}
            />
          </GridDX>
          <GridDX item xs={6}>
            <TextFieldDX
              label={t("Description")}
              name="description"
              value={correspondenceData.description}
              onChange={handleInputChange}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Correspondence Date")}
              sx={{ flex: 1 }}
              name="correspondenceDate"
              value={correspondenceData.correspondenceDate}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange(value, "correspondenceDate");
              }}
            />
          </GridDX>
          <GridDX item xs={6}>
            <DatePickerDX
              label={t("Due Date")}
              sx={{ flex: 1 }}
              name="dueDate"
              minDate={date}
              value={correspondenceData.dueDate}
              calendarId={calendarId}
              onChange={(value: any) => {
                handleDateChange(value, "dueDate");
              }}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Importance Level")}
              items={importanceLevelList.map((level: any) => {
                return {
                  value: level.importanceLevelId,
                  text: languageIsEn ? level.enName : level.arName,
                };
              })}
              name="importanceLevelId"
              value={correspondenceData.importanceLevelId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Privacy Level")}
              items={privacyLevelList.map((level: any) => {
                return {
                  value: level.privacyLevelId,
                  text: languageIsEn ? level.enName : level.arName,
                };
              })}
              name="privacyLevelId"
              value={correspondenceData.privacyLevelId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Domain Type")}
              items={domainTypeList.map((type: any) => {
                return {
                  value: type.domainTypeId,
                  text: languageIsEn ? type.enName : type.arName,
                };
              })}
              name="domainTypeId"
              value={correspondenceData.domainTypeId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={6}>
            <SelectListDX
              label={t("Delivery Method")}
              items={deliveryList.map((method: any) => {
                return {
                  value: method.deliveryMethodId,
                  text: languageIsEn ? method.enName : method.arName,
                };
              })}
              name="deliveryMethodId"
              value={correspondenceData.deliveryMethodId}
              onChange={handleInputChange}
              disabled={isLoading}
              // error={}
            />
          </GridDX>
          <GridDX item xs={12}>
            <CheckBoxDX
              name="applyWatermark"
              label={t("Apply Watermark")}
              checked={correspondenceData.applyWatermark}
              onChange={handleInputChange}
            />
          </GridDX>
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("Notes")}
              fullWidth
              multiline
              name="notes"
              rows={3}
              value={correspondenceData.notes}
              onChange={handleInputChange}
              // error={}
            />
          </GridDX>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ flexWrap: "wrap", justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={onClose}
          sx={{ mb: { xs: 2, sm: "auto" } }}
        >
          {t("Cancel")}
        </ButtonDX>
        <LoadingButtonDX
          onClick={() => onSave(CorrespondenceStatus.Draft)}
          loading={isSaving}
          loadingPosition="end"
        >
          {t("Save as Draft")}
        </LoadingButtonDX>
        <LoadingButtonDX
          onClick={() => onSave(CorrespondenceStatus.Pending)}
          loading={isSaving}
          loadingPosition="end"
        >
          {t("Save and Send")}
        </LoadingButtonDX>
      </DialogActions>
    </Dialog>
  );
};

export default EditCorrespondenceModal;