import { CardContent, Divider, Typography } from "@mui/material";
import GridDX from "../../layout/griddx";
import Loading from "../../loading";
import ButtonDX from "../../controls/buttondx";
import { useEffect, useState } from "react";
import CardDX from "../../layout/carddx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { useTranslation } from "react-i18next";
import TextFieldDX from "../../controls/textfielddx";
import BoxDX from "../../layout/boxdx";
import MenuButtonDX from "../../controls/menubutton";
import MenuItemDX from "../../controls/menuitemdx";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../controls/deleteModal";
import CopyModal from "../../editmodals/DMS/copymodal";
import MoveModal from "../../editmodals/DMS/movemodal";
import FileModal from "../../editmodals/DMS/filemodal";
import FileViewerModal from "../../editmodals/DMS/fileviewermodal";
import FileInformationTab from "./fileinformationtab";
import useDocumentService from "../../../shared/services/documentservice";
import { useNotificationContext } from "../../../context/notificationcontext";
import DocumentDrawer from "./documentdrawer";
import { DateFormatter, getMIMEType } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import DocumentUploadModal from "../../editmodals/DMS/documentuploadmodal";

const FileInformationPage = (props: any) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { setError, setInfo }= useNotificationContext();
  const { getDocumentByID, downloadDocument, moveDocument, deleteDocument } = useDocumentService();

  const [isLoading, setIsLoading] = useState(false);
  const [refetchActivity, setRefetchActivity] = useState(false);
  const [fileData, setFileData] = useState<any>(null);
  const [editFile, setEditFile] = useState<any>(null);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [openFileViewer, setOpenFileViewer] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState({ open: false, id: null, document: false });
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);

  useEffect(() => {
    getDetails(parseInt(id as string));
  }, []);

  const getDetails = async (id: number) => {
    setIsLoading(true);
    getDocumentByID(id)
      .then((res) => setFileData(res))
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toDownloadDocument = async () => {
    downloadDocument(fileData.documentId)
      .then((res) => {
        const blob = new Blob([res], { type: getMIMEType(fileData.fileExtension) });
        const file = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", fileData.enName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err: any) => setError(err))
  };

  const toMove = async () => {
    if (openMoveModal.document) {
      const data = {
        documentId: openMoveModal.id,
        newFolderId: selectedFolder.folderId
      };
      moveDocument(data)
      .then((res: any) => {
        setInfo(t("Document moved successfully"));
        onClose(true);
      })
      .catch((err: any) => setError(err));
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    deleteDocument(fileData.documentId)
      .then((res: any) => {
        setInfo(t("Document deleted successfully"));
        setDeleteModal(false);
        navigate("/documents");
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };
  
  const onClose = (refreshPage: boolean = false) => {
    setOpenFileModal(false);
    setOpenMoveModal({ open: false, id: null, document: false });
    setOpenCopyModal(false);
    setDeleteModal(false);

    if (refreshPage) {
      getDetails(parseInt(id as string));
      setRefetchActivity(true);
    }
  };
  
  return(
    <GridDX container flexDirection="column" sx={{ pt: 1 }}>
      {isLoading && <Loading styles={{ height: "100%", width: "100%" }} />}
      {openFileViewer && (
        <FileViewerModal open={openFileViewer} handleClose={()=>setOpenFileViewer(false)} document={fileData}/>
      )}
      {openFileModal && (
        <DocumentUploadModal open={openFileModal} handleClose={onClose} folderId={editFile.folderId} data={editFile}/>
      )}
      {openMoveModal.open && (
        <MoveModal open={openMoveModal.open} handleClose={onClose} folder={selectedFolder} onFolderSelect={setSelectedFolder} toMove={toMove}/>
      )}
      {openCopyModal && (
        <CopyModal open={openCopyModal} handleClose={onClose} />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          onDeleteClick={onDelete}
          onCancelClick={onClose}
        />
      )}
      <GridDX item xs={12} justifyContent="space-between">
        <Typography fontSize={25} gutterBottom>
          {fileData?.enName}
        </Typography>
        <BoxDX sx={{display: "flex", flexDirection: "row",}}>
          <ButtonDX sx={{width: 100}} onClick={()=>navigate(`/documents`)}>
            {t("Back")}
          </ButtonDX>
          <DocumentDrawer documentId={parseInt(id as string)}/>
          <MenuButtonDX sx={{ width: 100 }} label={t("Actions")}>
            <MenuItemDX onClick={() => {
                setEditFile(fileData);
                setOpenFileModal(true);
              }}
            >
              {t("Edit")}
            </MenuItemDX>
            {!(["docx", "xlsx", ".pptx"].includes(fileData?.fileExtension)) && (
              <MenuItemDX onClick={()=>setOpenFileViewer(true)}>{t("Preview")}</MenuItemDX>
            )}
            <MenuItemDX onClick={()=>toDownloadDocument()}>{t("Download")}</MenuItemDX>
            <MenuItemDX onClick={()=>setOpenCopyModal(true)}>{t("Copy")}</MenuItemDX>
            <MenuItemDX onClick={()=>setOpenMoveModal({ open: true, id: fileData.documentId, document: true })}>{t("Move")}</MenuItemDX>
            <MenuItemDX onClick={()=>setDeleteModal(true)}>{t("Delete")}</MenuItemDX>
          </MenuButtonDX>
        </BoxDX>
      </GridDX>
      <CardDX>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <InfoOutlinedIcon fontSize="medium" /> {t("Basic Information")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <GridDX fullWidth>
            <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Creation Date")}
                    name="creationDate"
                    placeholder={t("No Information")}
                    value={DateFormatter(fileData?.createdDate, calendarId, gregorianDateFormatId, hijriDateFormatId)}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Owner")}
                    name="owner"
                    placeholder={t("No Information")}
                    value={(i18n.language === "en" ? fileData?.createdByUser.enFullName : fileData?.createdByUser.arFullName)}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Description")}
                    name="Test File"
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Physical Location")}
                    name="location"
                    placeholder={t("No Information")}
                    value={fileData?.dirLocation.split("com/")[1]}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Categories")}
                    name="categories"
                    placeholder={t("No Information")}
                  />
                </GridDX>
                <GridDX item xs={6}>
                  <TextFieldDX
                    readOnly
                    label={t("Active Version")}
                    name="activeVersion"
                    placeholder={t("No Information")}
                  />
                </GridDX>
              </GridDX>
          </GridDX>
        </CardContent>
      </CardDX>
      <CardDX sx={{ mt: 2 }}>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <AutoAwesomeMosaicOutlinedIcon fontSize="medium" /> {t("Profiles")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <Typography textAlign={"center"} sx={{width: "100%", color: "grey"}}>
            {t("No Information")}
          </Typography>
        </CardContent>
      </CardDX>
      <CardDX sx={{ mt: 2, width: "100%" }}>
        <CardContent>
          <Typography fontSize={22} gutterBottom>
            <FormatListBulletedOutlinedIcon fontSize="medium" /> {t("Other Information")}
          </Typography>
          <Divider sx={{ mb: 1 }} />
          <FileInformationTab
            documentId={parseInt(id as string)}
            enFileName={fileData?.enName + fileData?.fileExtension}
            arFileName={fileData?.arName + fileData?.fileExtension}
            refetchActivity={refetchActivity}
          />
        </CardContent>
      </CardDX>
    </GridDX>
  );
};

export default FileInformationPage;