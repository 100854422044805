import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

import GridDX from "../../../components/layout/griddx";
import WhiteBox from "../../../components/layout/whitebox";
import ChartBox from "../../../components/layout/chartbox";
import CorrespondenceListTabs from "./correspondencelisttabs";

import useDashboardService from "../../../shared/services/dashboardservice";

import { useNotificationContext } from "../../../context/notificationcontext";

const Correspondence = () => {
  const { t } = useTranslation();
  const { setError } = useNotificationContext();
  const {
    getSentAndNotReceivedCount,
    getRequiresMyActionCount,
    getOverDueBreakupCount,
    getOpenBreakupCount,
  } = useDashboardService();
  const [isLoading, setIsLoading] = useState(true);
  const [sentAndNotReceivedCount, setSentAndNotReceivedCount] = useState(0);
  const [requiresMyActionCount, setRequiresMyActionCount] = useState(0);
  const [overdueBreakup, setOverdueBreakup] = useState<any>([
    { id: 0, value: 0, label: t("By Me") },
    { id: 1, value: 0, label: t("By Others") },
  ]);
  const [openBreakup, setOpenBreakup] = useState<any>([
    { id: 0, value: 0, label: t("By Me") },
    { id: 1, value: 10, label: t("By Others") },
  ]);

  useEffect(() => {
    Promise.all([
      getSentAndNotReceivedCount().then((data: number) =>
        setSentAndNotReceivedCount(data)
      ),
      getRequiresMyActionCount().then((data: number) =>
        setRequiresMyActionCount(data)
      ),
      getOverDueBreakupCount().then((data: any) => {
        setOverdueBreakup((oldValue: any) => {
          const newValue = oldValue.slice();

          newValue[0].value = data.overdueByMe;
          newValue[1].value = data.overdueByOthers;

          return newValue;
        });
      }),
      getOpenBreakupCount().then((data: any) =>
        setOpenBreakup((oldValue: any) => {
          const newValue = oldValue.slice();

          newValue[0].value = data.openByMe;
          newValue[1].value = data.openByOthers;

          return newValue;
        })
      ),
    ])
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <GridDX
      container
      sx={{ width: "100%", height: "100%", my: 1 }}
      alignItems="center"
      columnSpacing={1}
      rowSpacing={2}
    >
      <GridDX item lg={3} xs={12}>
        <WhiteBox
          title={t("Sent & Not Recieved")}
          loading={isLoading}
          count={sentAndNotReceivedCount}
          icon={<ForwardToInboxOutlinedIcon sx={{ fontSize: 100 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <WhiteBox
          title={t("Requires my Action")}
          loading={isLoading}
          count={requiresMyActionCount}
          icon={<MarkEmailReadOutlinedIcon sx={{ fontSize: 100 }} />}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox
          title={t("Overdue")}
          loading={isLoading}
          data={overdueBreakup}
          colors={["#773dbd", "#A3A3A3"]}
        />
      </GridDX>
      <GridDX item lg={3} xs={12}>
        <ChartBox
          title={t("Open")}
          loading={isLoading}
          data={openBreakup}
          colors={["#773dbd", "#A3A3A3"]}
        />
      </GridDX>
      <GridDX item xs={12}>
        <CorrespondenceListTabs />
      </GridDX>
    </GridDX>
  );
};

export default Correspondence;
