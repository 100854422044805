import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import GridDX from "../../layout/griddx";
import { items } from "../../pages/DMS/data";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider, IconButton } from "@mui/material";
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';

const CopyModal = (props: any) => {
  const { t, i18n } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTree, setActiveTree] = useState<any>([1]);

  return(
    <AddEditModalDX
      open={props.open}
      handleClose={props.handleClose}
      isSaving={isLoading}
      title={t("Copy")}
      maxWidth="md"
      onSaveClick={props.handleClose}
      onYesClick={props.handleClose}
    >
      <GridDX
      container
      sx={{ width: "100%", height: "100%" }}
    >
      <GridDX
        item
        xs={12}
        sx={{
          overflowX: "hidden",
          flexDirection: "column",
          height: "90%",
        }}
      >
        <List
          sx={{ width: "100%", direction: i18n.dir(), maxHeight: 800 }}
          disablePadding
        >
          {(
            items.map((item: any) => (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={item.id === activeTree[0]}
                    onClick={() => {
                      setSelectedIndex(item.id);
                      setActiveTree(item.tree);
                    }}
                  >
                    <ListItemIcon>
                      <DnsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        i18n.language === "en" ? item.enTitle : item.arTitle
                      }
                      primaryTypographyProps={
                        (item.id === activeTree[0])
                          ? { textAlign: "start", fontWeight: "bold" }
                          : { textAlign: "start" }
                      }
                    />
                  </ListItemButton>
                </ListItem>      
                <Collapse in={item.id === activeTree[0]} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
                  <List component="div" disablePadding sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
                    {item.subDirectories.map((subDirectory: any) => (
                    <SubDirectories directory={subDirectory} tree={activeTree} onDirectoryChange={setActiveTree}/>
                    ))}
                  </List>
                </Collapse>
                <Divider />
              </>
            ))
          )}
        </List>
      </GridDX>
    </GridDX>
    </AddEditModalDX>
    
  )
};

export default CopyModal;

const SubDirectories = (props: any) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  if (props.directory.length === 0) return (<></>);
  else return (
    <>
    <ListItem disablePadding>
      <ListItemButton
        selected={JSON.stringify(props.tree) === JSON.stringify(props.directory.tree)}
        onClick={() => {
          props.onDirectoryChange(props.directory.tree);
        }}
      >
        <ListItemText
          primary={
            i18n.language === "en" ? props.directory.enTitle : props.directory.arTitle
          }
          primaryTypographyProps={
            (JSON.stringify(props.tree) === JSON.stringify(props.directory.tree))
              ? { textAlign: "start", fontWeight: "bold" }
              : { textAlign: "start" }
          }
        />
      </ListItemButton>
      <IconButton
        edge="end"
        sx={{mx: 1}}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {props.directory.subDirectories.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
      </IconButton>
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit sx={i18n.language === "en" ? {pl: 3} : {pr: 3}}>
      <List component="div" disablePadding sx={i18n.language === "en" ? {borderLeft: 1} : {borderRight: 1}}>
          {props.directory.subDirectories.map((subDirectory: any) => (
            <SubDirectories directory={subDirectory} tree={props.tree} onDirectoryChange={props.onDirectoryChange}/>
          ))}
      </List>
    </Collapse>
    </>
  );
};