import { useState, useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

import AttachmentModal from "../../../../components/editmodals/correspondence/correspondenceattachmentsmodal";
import ListPageDX from "../../../../components/business/listpagedx";

import useAttachmentService from "../../../../shared/services/attachmentservice";
import {
  CorrespondenceStatus,
  DateTimeFormatter,
  DateSorter,
} from "../../../../shared/globals";

import { useNotificationContext } from "../../../../context/notificationcontext";
import { useAuthContext } from "../../../../context/authcontext";
import { useNavigate } from "react-router";

const CorrespondenceAttachments = (props: any) => {
  const { status } = props;
  const { userData } = useAuthContext();
  const { calendarId, gregorianDateFormatId, hijriDateFormatId } = userData;
  const { t, i18n } = useTranslation();
  const languageIsEn = i18n.language === "en";
  const navigate = useNavigate();
  const { getAttachments, deleteAttachment } =
    useAttachmentService();
  const { setError, setInfo } = useNotificationContext();

  const [rows, setRows] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAttachmentsList();
  }, [i18n.language]);

  const getAttachmentsList = async () => {
    setIsLoading(true);
    getAttachments(props.correspondenceId)
      .then((response: any) => {
        setAttachments(response);
        setRows(response);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = (id: any) => {
    setIsLoading(true);
    deleteAttachment(id)
      .then(() => {
        setInfo(t("Data deleted successfully"));
        getAttachmentsList();
        props.getDetails(props.correspondenceId);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toCreate = () => {
    setOpen(true);
  };

  const toView = (row: any) => {
    setIsLoading(true);
    fetch(row.dirLocation, {
      headers: {Authorization: `Bearer ${userData.tokenInfo.token}`}
    }).then(async (res) => {
        const arrayBuffer = await res.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        const link = window.URL.createObjectURL(blob);
        navigate("/attachment", {
          state: {
            data: {
              enName: `${row.enName}`,
              arName: `${row.arName}`,
              applyWatermark: props.applyWatermark,
              correspondenceId: props.correspondenceId,
            },
            file: link,
            update: false,
          },
        });
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const toClose = (refreshPage: boolean = false) => {
    setOpen(false);

    if (refreshPage) {
      getAttachmentsList();
      props.getDetails(props.correspondenceId);
    }
  };

  const columns: GridColDef[] = [
    {
      field: languageIsEn ? "enName" : "arName",
      headerName: `${t("Name")}`,
      width: 250,
    },
    {
      field: "description",
      headerName: `${t("Description")}`,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "createdByUser",
      headerName: `${t("Created By")}`,
      flex: 1,

      valueGetter: (params: any) => {
        return (
          params.row.createdByUser &&
          (languageIsEn
            ? params.row.createdByUser.enFullName
            : params.row.createdByUser.arFullName)
        );
      },
    },
    {
      field: "createdDate",
      headerName: `${t("Date Added")}`,
      flex: 1,
      valueGetter(params) {
        return DateTimeFormatter(
          params.row.createdDate,
          calendarId,
          gregorianDateFormatId,
          hijriDateFormatId
        );
      },
      sortComparator: DateSorter,
    },
  ];

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(attachments);
      return;
    }

    const lowercasedValue = value.toLowerCase();

    const newRows = attachments.filter(
      ({ enName, arName, description, createdByUser }: any) =>
        languageIsEn
          ? enName.toLowerCase().includes(lowercasedValue) ||
            description.toLowerCase().includes(lowercasedValue) ||
            createdByUser.enFullName.toLowerCase().includes(lowercasedValue)
          : arName.includes(value) ||
            description.toLowerCase().includes(lowercasedValue) ||
            createdByUser.arFullName.includes(value)
    );
    setRows(newRows);
  };

  return (
    <>
      {open && (
        <AttachmentModal
          open={open}
          handleClose={toClose}
          correspondenceId={props.correspondenceId}
        />
      )}
      <ListPageDX
        listTitle={t("Attachments")}
        name={t("Attachment")}
        createLabel={t("Add Attachments")}
        editLabel={t("View")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.attachmentId}
        isLoading={isLoading}
        toCreate={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? toCreate
            : null
        }
        onDelete={
          status == CorrespondenceStatus.Recall ||
          status == CorrespondenceStatus.Draft
            ? onDelete
            : null
        }
        onEdit={toView}
        exportToPDF={false}
        exportToCSV={false}
        setGridFilterCriteria={setGridFilterCriteria}
      />
    </>
  );
};

export default CorrespondenceAttachments;
