import { GridColDef } from "@mui/x-data-grid";
import ListPageDX from "../../../business/listpagedx";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TransactionModal from "../../../editmodals/DMS/transactionmodal";

const FileTransactions = (props: any) => {
  const { t } = useTranslation();

  const rows = [
    {
      id: 0,
      date: "01-01-2023 04:00 PM",
      receiver: "John",
      status: "Unreturned"
    },
  ];
  
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("ID")}`,
      flex: 1,
    },{
      field: "receiver",
      headerName: `${t("Receiver")}`,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  return(
    <>
    {open && 
      <TransactionModal
        open={open}
        handleClose={() => setOpen(false)}
        correspondenceId={props.correspondenceId}
      />
    }
    <ListPageDX
      listTitle={t("Movement Transactions")}
      name={t("Movement Transactions")}
      createLabel={t("Add Transaction")}
      rows={rows}
      columns={columns}
      getRowId={(row: any) => row.id}
      isLoading={isLoading}
      exportToPDF={false}
      exportToCSV={false}
      toCreate={() => setOpen(true)}
      onDelete={()=>{}}
    />
    </>
  );
};

export default FileTransactions;