export const items = [
  { 
    id: 1,
    enTitle: "Admin",
    arTitle: "مسؤل",
    tree: [1],
    files: [],
    subDirectories: [
      { 
        id: 1,
        enTitle: "2021",
        arTitle: "2021",
        files: [
          {
            id: 1,
            enTitle: "Invoice Reciept",
            arTitle: "استلام الفاتورة",
            type: "pdf",
            url: ""
          },
          {
            id: 2,
            enTitle: "Test Image",
            arTitle: "صورة الاختبار",
            type: "img",
            url: ""
          },
        ],
        subDirectories: [],
        tree: [1, 1],
      },
      { 
        id: 2,
        enTitle: "2022",
        arTitle: "2022",
        files: [],
        subDirectories: [
          { 
            id: 1,
            enTitle: "General",
            arTitle: "عام",
            files: [
              {
                id: 1,
                enTitle: "Test Document",
                arTitle: "وثيقة الاختبار",
                type: "pdf",
                url: ""
              },
            ],
            subDirectories: [],
            tree: [1, 2, 1],
          },
          { 
            id: 2,
            enTitle: "Classified",
            arTitle: "صنف",
            files: [],
            subDirectories: [
              { 
                id: 1,
                enTitle: "Old",
                arTitle: "قديم",
                files: [
                  {
                    id: 1,
                    enTitle: "Test Document",
                    arTitle: "وثيقة الاختبار",
                    type: "pdf",
                    url: ""
                  },
                ],
                subDirectories: [],
                tree: [1, 2, 2, 1],
              },
            ],
            tree: [1, 2, 2],
          },
        ],
        tree: [1, 2],
      },
    ]
  },
  { 
    id: 2,
    enTitle: "Finance",
    arTitle: "تمويل",
    tree: [2],
    files: [],
    subDirectories: [
      { 
        id: 1,
        enTitle: "2022",
        arTitle: "2022",
        files: [
          {
            id: 2,
            enTitle: "Test Image",
            arTitle: "صورة الاختبار",
            type: "img",
            url: ""
          },
        ],
        subDirectories: [
          { 
            id: 1,
            enTitle: "Classified",
            arTitle: "صنف",
            files: [],
            subDirectories: [],
            tree: [2, 1, 1],
          },
        ],
        tree: [2, 1],
      },
      { 
        id: 2,
        enTitle: "2023",
        arTitle: "2023",
        files: [
          {
            id: 2,
            enTitle: "Test Image",
            arTitle: "صورة الاختبار",
            type: "img",
            url: ""
          },
        ],
        subDirectories: [],
        tree: [2, 2],
      },
    ]
  },
];