import { useTranslation } from "react-i18next";
import BoxDX from "../../../components/layout/boxdx";
import DynamicTabs from "../../../components/layout/dynamictab";
import CorrespondenceList from "./correspondencelist";
import ArchivedCorrespondenceList from "./archivedlist";

function CorrespondenceListTabs() {
  const { t } = useTranslation();
  return (
    <BoxDX
      bgcolor={"white"}
      boxShadow={
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
      }
      sx={{ width: "100%", p: 1 }}
    >
      <DynamicTabs
        tabLabels={[t("Correspondence"), t("Archived")]}
        tabContents={[<CorrespondenceList />, <ArchivedCorrespondenceList />]}
      />
    </BoxDX>
  );
}

export default CorrespondenceListTabs;
