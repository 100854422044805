import { PieChart } from "@mui/x-charts";
import { useTranslation } from "react-i18next";
const PieChartDx = (props: any) => {
  const { t, i18n } = useTranslation();
  const { data, colors } = props;
  const languageIsEn = i18n.language === "en";

  //     const data = [
  //       { label: t("Internal Outgoing"), id: "IO", value: 173, color: "blue" },
  //       { label: t("External Outgoing"), id: "EO", value: 114, color: "orange" },
  //       { label: t("Internal Incoming"), id: "EI", value: 75, color: "green" },

  //   ];
  return (
    <PieChart
      colors={colors}
      series={[
        {
          arcLabel: (item) => `${item.value}`,
          arcLabelMinAngle: 45,
          innerRadius: 50,
          outerRadius: 90,
          cx: 85,
          cy: 100,
          data: data,
        },
      ]}
      legend={{
        direction: "column",
        position: {
          vertical: "middle",
          horizontal: "right",
        },
      }}
      sx={{
        "--ChartsLegend-rootOffsetX": languageIsEn ? "-35px" : "95px",
        "--ChartsLegend-rootOffsetY": "-50px",
        "--ChartsLegend-labelSpacing": languageIsEn ? "15px" : "-40px",
      }}
      height={300}
      width={350}
    />
  );
};

export default PieChartDx;
